import {
  Container,
  List,
  ListItem,
  Divider,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import Box from "@mui/material/Box";
import { useTranslation } from "common/locales";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import FeedPurchaseEventList from "./FeedPurchaseEventList";
import { mixpanel } from "common/analytics";
import { useAppSelector, selectCurrentFarm, useAppDispatch } from "model";
import { METABASE_TYPES, getMetabaseUrl } from "model/metabaseUrlSlice";

export default function FeedPurchaseDetail() {
  const history = useHistory();
  const { t } = useTranslation();
  const currentFarm = useAppSelector(selectCurrentFarm);
  const metabaseId = 27;
  const [metabaseUrl, setMetabaseUrl] = useState<string>("");
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const visualizationId = "AVAILABLE_STOCK_SUMMARY";

  useEffect(() => {
    if (!metabaseUrl) {
      const workaroundFunc = async () => {
        const params: { [name: string]: string } = {};
        params["date_filter"] = "2020-07-24T14:15:22.000Z";
        try {
          const res = await dispatch(
            getMetabaseUrl({
              visualizationId,
              farmGuid: currentFarm?.guid ?? "",
              params,
            })
          ).then(unwrapResult);
          setMetabaseUrl(res.url);
        } catch (e) {
          console.log(e);
        }
      };
      void workaroundFunc();
    }
  }, [currentFarm?.guid, metabaseUrl, dispatch]);

  return (
    <>
      <Container>
        <List>
          <ListItem key="add-feed-purchase">
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                mixpanel.track("Add new feed purchase event clicked");
                history.push(`/enter-feed-purchase-events`);
              }}
            >
              {t("feedPurchaseDetailView.button.addFeedPurchase.title")}
            </Button>
          </ListItem>
          <ListItem key="add-feed-event">
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                mixpanel.track("Add new feed event clicked");
                history.push(`/enter-feed-events`);
              }}
            >
              {t("feedEventDetailView.button.addFeedEvent.title")}
            </Button>
          </ListItem>
        </List>
      </Container>
      <Divider />
      <FieldEventListContainer>
        <Box
          my={4}
          display="flex"
          alignItems="center"
          gap={4}
          p={0}
          sx={{ border: "2px solid grey", borderRadius: 2 }}
        >
          {metabaseUrl && (
            <iframe
              frameBorder="0"
              src={metabaseUrl}
              width={theme.spacing(65)}
              height={theme.spacing(60)}
              title="Feed-in-Store"
            />
          )}
        </Box>
      </FieldEventListContainer>
      <FieldEventListContainer>
        <FeedPurchaseEventList />
      </FieldEventListContainer>
    </>
  );
}

const FieldEventListContainer = styled(Container)`
  flex-grow: 1;
`;
