export enum MACHINERY {
  SPRAYER = "SPRAYER",
  MOWER = "MOWER",
  SEED_BROADCASTER = "SEED_BROADCASTER",
  DIRECT_DRILL = "DIRECT_DRILL",
  MANURE_SPREADER = "MANURE_SPREADER",
  SLURRY_SPREADER = "SLURRY_SPREADER",
  BALER = "BALER",
}
export enum ARABLE_TYPE {
  WHEAT = "WHEAT",
  BARLEY = "BARLEY",
  OILSEED_RAPE = "OILSEED_RAPE",
  POTATO = "POTATO",
  MAIZ = "MAIZ",
  CEREALS = "CEREALS",
  VEGETABLES_SALAD = "VEGETABLES_SALAD",
  OTHERS = "OTHERS",
}

export enum FIELD_TYPE {
  GRASS = "GRASS",
  ARABLE = "ARABLE",
}

export enum YIELD_TYPES {
  GRASS = "GRASS",
  MILK = "MILK",
  MEAT = "MEAT",
  CROP = "CROP",
}
export enum GRASS_YIELD_TYPES {
  HAY = "HAY",
  SILAGE = "SILAGE",
  HAYLAGE = "HAYLAGE",
}

enum CROP_BY_PRODUCT {
  STRAW = "STRAW",
}

export const CROP_YIELD_TYPE = {
  ...CROP_BY_PRODUCT,
  ...ARABLE_TYPE,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CROP_YIELD_TYPE = CROP_BY_PRODUCT | ARABLE_TYPE;
