import DropdownInput from "common/components/DropdownInput";
import { useTranslation } from "common/locales";
import {
  Theme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { SEEDING_METHOD } from "model/reseedEventSlice";

interface SeedingMethodDropdownProps {
  seedingMethod: string;
  onChange: (value?: SEEDING_METHOD) => void;
  seedingMethodInputError: boolean;
  label: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      maxWidth: 400,
      paddingBottom: theme.spacing(2),
    },
  })
);

export default function SeedingMethodDropdown({
  onChange,
  seedingMethod,
  seedingMethodInputError,
  label,
}: SeedingMethodDropdownProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControl
      className={classes.formControl}
      error={seedingMethodInputError}
      fullWidth
    >
      <InputLabel
        id="seedingMethod-select-label"
        variant="standard"
        shrink={true}
        error={seedingMethodInputError}
      >
        {t(label)}
      </InputLabel>
      <Select
        labelId="seedingMethod-select-label"
        id="seedingMethod-select"
        value={seedingMethod ?? ""}
        onChange={onChangeSelect}
        input={<DropdownInput />}
        error={seedingMethodInputError}
      >
        {Object.entries(SEEDING_METHOD).map(([key, value]) => (
          <MenuItem value={value} key={key}>
            {t(`reseedEvent.seedingMethod.option.${value}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  function onChangeSelect(e: any) {
    onChange(e.target.value);
  }
}
