import DropdownInput from "common/components/DropdownInput";
import { useTranslation } from "common/locales";
import {
  Theme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { GENERAL_FEED_UNITS } from "model";

interface FeedUnitDropdownProps {
  currentUnit?: GENERAL_FEED_UNITS;
  disabled: boolean;
  onChange: (e: SelectChangeEvent<GENERAL_FEED_UNITS>) => void;
  feedUnitInputError: boolean;
  label: string;
  name: string;
}

export const useVegetationStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      maxWidth: 400,
      paddingBottom: theme.spacing(2),
    },
  })
);

export default function FeedUnitDropdown({
  disabled,
  onChange,
  currentUnit,
  feedUnitInputError,
  label,
  name,
}: FeedUnitDropdownProps) {
  const classes = useVegetationStyles();
  const { t } = useTranslation();

  return (
    <FormControl
      className={classes.formControl}
      disabled={disabled}
      error={feedUnitInputError}
      fullWidth
    >
      <InputLabel
        id="feed-unit-type-select-label"
        variant="standard"
        shrink={true}
        error={feedUnitInputError}
        required
      >
        {t(label)}
      </InputLabel>
      <Select
        labelId="feed-unit-type-select-label"
        id="feed-unit-type-select"
        value={currentUnit ?? ("" as GENERAL_FEED_UNITS)}
        onChange={onChange}
        input={<DropdownInput />}
        error={feedUnitInputError}
        name={name}
      >
        {Object.entries(GENERAL_FEED_UNITS).map(([key, value]) => (
          <MenuItem value={value} key={key}>
            {t(`feedPurchase.feedUnit.option.${value}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
