import DropdownInput from "common/components/DropdownInput";
import {
  Theme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

interface NameAndGuidDropdownMenu {
  items: NameAndGuid[];
  disabled: boolean;
  onChange: (e: SelectChangeEvent<string[]>) => void;
  selectInputError: boolean;
  selectedItems: NameAndGuid[];
  label: string;
  name: string;
  required: boolean;
}

export class NameAndGuid {
  name?: string;
  guid?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      maxWidth: 400,
      paddingBottom: theme.spacing(2),
    },
  })
);

export default function NameAndGuidMultiSelectDropdown({
  disabled,
  onChange,
  items,
  selectInputError,
  selectedItems,
  label,
  name,
  required,
}: NameAndGuidDropdownMenu) {
  const classes = useStyles();
  return (
    <FormControl
      className={classes.formControl}
      disabled={disabled}
      error={selectInputError}
      fullWidth
    >
      <InputLabel
        id="nameAndGuid-select-label"
        variant="standard"
        shrink={true}
        error={selectInputError}
        required={required}
      >
        {label}
      </InputLabel>
      <Select
        labelId="nameAndGuid-select-label"
        id="nameAndGuid-select"
        value={selectedItems.map((item: NameAndGuid) => item.guid ?? "")}
        onChange={onChange}
        multiple={true}
        input={<DropdownInput />}
        error={selectInputError}
        renderValue={(selected) => {
          return selectedItems.map((item: NameAndGuid) => item.name).join(", ");
        }}
        name={name}
      >
        {items.map((nameAndGuid) => (
          <MenuItem value={nameAndGuid.guid} key={nameAndGuid.guid}>
            <Checkbox
              checked={
                selectedItems &&
                selectedItems.findIndex(
                  (selectedItem) => selectedItem.guid === nameAndGuid.guid
                ) > -1
              }
            />
            <ListItemText primary={nameAndGuid.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
