import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import { combineReducers, configureStore, Middleware } from "@reduxjs/toolkit";
import {
  userReducer,
  animalGroupReducer,
  stockClassReducer,
  readingsReducer,
  farmsReducer,
  fieldReducer,
  mapReducer,
  appReducer,
  fetchUserById,
  fetchToken,
  reseedEventReducer,
  arableFieldReducer,
} from "model";
import LogRocket from "logrocket";
import { getLogRocketReduxMiddlewareConfig } from "../common/analytics/logRocketOptions";
import { defaultStockClassesReducer } from "./defaultStockClassesSlice";
import { stockClassBirthingPeriodReducer } from "./stockClassBirthingPeriodSlices";
import { stockClassFeedAllocationReducer } from "./stockClassFeedAllocationSlice";
import { farmerImageUploadReducer } from "./farmerImageUploadSlice";
import { diaryEntryReducer } from "./diaryEntrySlice";
import { feedStockReducer } from "./feedStockSlice";
import { feedPurchaseEventReducer } from "./feedPurchaseEventSlice";
import { feedEventReducer } from "./feedEventSlice";
import { yieldEventReducer } from "./yieldEventSlice";
import { fertilizerEventReducer } from "./fertilizerEventSlice";
import { fieldPreparationEventReducer } from "./fieldPreparationEventSlice";
import { metabaseUrlReducer } from "./metabaseUrlSlice";

const combinedReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  farms: farmsReducer,
  fields: fieldReducer,
  arableFields: arableFieldReducer,
  farmerImageUploads: farmerImageUploadReducer,
  diaryEntries: diaryEntryReducer,
  reseedEvents: reseedEventReducer,
  map: mapReducer,
  animalGroups: animalGroupReducer,
  stockClasses: stockClassReducer,
  readings: readingsReducer,
  defaultStockClasses: defaultStockClassesReducer,
  stockClassBirthingPeriods: stockClassBirthingPeriodReducer,
  stockClassFeedAllocations: stockClassFeedAllocationReducer,
  feedStock: feedStockReducer,
  feedPurchaseEvents: feedPurchaseEventReducer,
  fieldPreparationEvents: fieldPreparationEventReducer,
  feedEvents: feedEventReducer,
  yieldEvents: yieldEventReducer,
  fertilizerEvents: fertilizerEventReducer,
  metabaseUrlEntries: metabaseUrlReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "RESET_STORE") {
    state = undefined;
  }

  return combinedReducer(state, action);
};

function getLogRocketMiddleware() {
  return LogRocket.reduxMiddleware(
    getLogRocketReduxMiddlewareConfig(
      fetchToken.fulfilled.type,
      fetchUserById.fulfilled.type
    )
  ) as Middleware<{}, RootState>; // eslint-disable-line @typescript-eslint/ban-types
}

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["feedEvents.entities", "payload.timestamp"],
        // Ignore these paths in the state
        ignoredPaths: ["items.dates"],
      },
    }).concat(getLogRocketMiddleware()),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

export function useAppDispatch(): AppDispatch {
  return useDispatch<AppDispatch>();
}

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
