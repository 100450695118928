import { useState } from "react";
import styled, { css } from "styled-components";
import {
  Button,
  Container,
  Divider,
  LinearProgress,
  TextField,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import {
  useAppSelector,
  MACHINERY,
  Field,
  selectAllFields,
  ArableField,
  selectAllArableFields,
  FieldPreparationEvent,
} from "model";
import { useTranslation } from "common/locales";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";

import * as Yup from "yup";
import EnterDate from "features/general/EnterDate";
import { mixpanel } from "common/analytics/mixpanel";
import ConfirmCancellationDialog from "features/general/ConfirmCancellationDialog";
import { ButtonContainer, ButtonGroup } from "./generalMethods";
import NameAndGuidMultiSelectDropdown from "features/general/NameAndGuidMultiSelectDropdown";
import { FieldPreparationEventFormData } from "./FieldPreparationEventFormData";

interface Props {
  fieldPreparationEvent: FieldPreparationEvent | undefined;
  handleSave: (
    fieldPreparationEventInput: FieldPreparationEventFormData
  ) => void;
  handleClose: () => void;
  fieldSelectDisabled: boolean;
}

const convertToFormInput = (
  fieldPreparationEvent: FieldPreparationEvent
): FieldPreparationEventFormData => {
  return {
    note: fieldPreparationEvent.note ?? undefined,
    machinery: fieldPreparationEvent.machinery ?? undefined,
    fieldPreparationEventDate: new Date(
      fieldPreparationEvent.fieldPreparationEventDate
    ),
    fieldGuids: fieldPreparationEvent.fieldGuids,
    fuel: fieldPreparationEvent.fuel,
    operationalCost: fieldPreparationEvent.operationalCost,
  };
};

export default function EnterFieldPreparationEvent({
  fieldPreparationEvent,
  handleSave,
  handleClose,
  fieldSelectDisabled,
}: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const loading = useAppSelector((state) => state.animalGroups.loading);

  const allGrassFields: Field[] = useSelector(selectAllFields);

  const allArableFields: ArableField[] = useSelector(selectAllArableFields);

  let allFields: (Field | ArableField)[] = [];
  allFields = allFields.concat(allArableFields);
  allFields = allFields.concat(allGrassFields);

  const validNumberGTE = (min = 0) =>
    Yup.number()
      .typeError(t("edit.minNumber.error", { min }))
      .min(min, t("edit.minNumber.error", { min }))
      .required(t("edit.required.error"));

  const FieldPreparationEventFormDataSchema = Yup.object({
    note: Yup.string().optional(),
    machinery: Yup.array()
      .of(Yup.mixed<MACHINERY>().oneOf(Object.values(MACHINERY)))
      .min(1)
      .required(),
    fuel: Yup.number().min(0).required(),
    operationalCost: Yup.lazy((value) =>
      value === "" ? Yup.string() : Yup.number().min(0)
    ).optional(),
    fieldGuids: Yup.array()
      .of(Yup.string().required())
      .min(1)
      .required("At least one field guid is required"),
    fieldPreparationEventDate: Yup.date().required(
      "FieldPreparation date is required"
    ),
  });

  if (loading) {
    return <LinearProgress />;
  }

  const initialFieldPreparationEvent = fieldPreparationEvent
    ? convertToFormInput(fieldPreparationEvent)
    : {
        note: "",
        fieldPreparationEventDate: new Date(),
        fieldGuids: [],
        machinery: [],
        fuel: "" as unknown as number,
      };
  return (
    <>
      <Formik
        enableReinitialize={false}
        initialValues={initialFieldPreparationEvent}
        validationSchema={FieldPreparationEventFormDataSchema}
        validateOnChange={false} // disable validation every field change, otherwise would validate all fields
        validateOnBlur={false} // disable validation every field blur, otherwise would validate all fields
        onSubmit={(
          fieldPreparationEventFormData: FieldPreparationEventFormData
        ) => {
          void handleSave(fieldPreparationEventFormData);
        }}
      >
        {({ values, handleChange, errors, validateField, setFieldValue }) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const handleInputChange = async (e: any, fieldName: string) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            await (handleChange(e) as any); // async, typings are wrong
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            await (validateField(fieldName) as any); // validate only changed field, async, typings are wrong
          };

          return (
            <>
              <Form id="fieldPreparation-event-input-form">
                <Container style={{ textAlign: "left", marginBottom: 40 }}>
                  <Typography variant="h6">
                    {t("fieldPreparationEventInput.title")}
                  </Typography>
                </Container>

                <Container style={{ textAlign: "left", marginBottom: 40 }}>
                  <Grid
                    container
                    display="flex"
                    alignItems="top"
                    marginBottom={4}
                  >
                    <Grid item xs={12} sm={6} display="flex" alignItems="top">
                      <EnterDate
                        disabled={false}
                        date={values.fieldPreparationEventDate}
                        setDate={async (date: Date | null) => {
                          await setFieldValue(
                            "fieldPreparationEventDate",
                            date,
                            false
                          );
                        }}
                        dateError={!!errors.fieldPreparationEventDate}
                        name={"fieldPreparationEventDate"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} display="flex" alignItems="top">
                      <NameAndGuidMultiSelectDropdown
                        disabled={fieldSelectDisabled}
                        required={true}
                        selectedItems={allFields
                          .filter((field: Field | ArableField) => {
                            return values.fieldGuids.includes(field.guid);
                          })
                          .map((field: Field | ArableField) => {
                            return {
                              guid: field.guid,
                              name: field.name,
                            };
                          })}
                        items={allFields.map((field: Field | ArableField) => {
                          return {
                            name: field.name,
                            guid: field.guid,
                          };
                        })}
                        onChange={(e) => handleInputChange(e, "fieldGuids")}
                        selectInputError={!!errors.fieldGuids}
                        label={t("fieldPreparationEventInput.fields.text")}
                        name={"fieldGuids"}
                      />
                    </Grid>
                  </Grid>

                  <Typography>
                    {t("fertilizer.operational.cost.heading")}
                  </Typography>

                  <Box
                    my={0}
                    mb={5}
                    display="flex"
                    alignItems="center"
                    gap={4}
                    p={3}
                    sx={{ border: "1px solid grey", borderRadius: 2 }}
                  >
                    <Grid
                      container
                      spacing={3}
                      display="flex"
                      alignItems="baseline"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="baseline"
                      >
                        <TextField
                          required
                          value={values.fuel}
                          type="number"
                          variant="standard"
                          disabled={false}
                          id="fuel"
                          error={errors?.fuel ? true : false}
                          onChange={async (event) => {
                            await handleInputChange(event, `fuel`);
                          }}
                          label={t("fieldPreparationEvent.fuel.label")}
                          maxRows={1}
                          name={`fuel`}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        display="flex"
                        alignItems="baseline"
                      >
                        <NameAndGuidMultiSelectDropdown
                          required={true}
                          selectedItems={Object.keys(MACHINERY)
                            .filter((key: string) => {
                              return (values.machinery as string[]).includes(
                                key
                              );
                            })
                            .map((key: string) => {
                              return {
                                guid: key,
                                name: t(
                                  `yieldEventInput.machinery.options.${key}`
                                ),
                              };
                            })}
                          items={Object.keys(MACHINERY).map((key: string) => {
                            return {
                              name: t(
                                `yieldEventInput.machinery.options.${key}`
                              ),
                              guid: key,
                            };
                          })}
                          disabled={false}
                          onChange={(e) => handleInputChange(e, "machinery")}
                          selectInputError={!!errors.machinery}
                          label={t(
                            "fieldPreparationEventInput.machinery.label"
                          )}
                          name={"machinery"}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="baseline"
                      >
                        <TextField
                          value={values.operationalCost}
                          type="number"
                          variant="standard"
                          disabled={false}
                          id="operationalCost"
                          error={errors?.operationalCost ? true : false}
                          onChange={async (event) => {
                            await handleInputChange(event, `operationalCost`);
                          }}
                          label={t("fertilizerEvent.operationalCost.label")}
                          maxRows={1}
                          name={`operationalCost`}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid
                    container
                    display="flex"
                    alignItems="baseline"
                    marginBottom={8}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      display="flex"
                      alignItems="baseline"
                    >
                      <StyledTextField
                        id="fieldPreparation-event-input-note"
                        label={t("reseedEvent.notes.label")}
                        autoFocus
                        value={values.note}
                        helperText={errors.note}
                        error={!!errors.note}
                        fullWidth
                        onChange={(e) => handleInputChange(e, "note")}
                        name={"note"}
                        variant="standard"
                        multiline
                      />
                    </Grid>
                  </Grid>
                </Container>
              </Form>
            </>
          );
        }}
      </Formik>
      <Divider />
      <ButtonContainer>
        <ButtonGroup>
          <Button color="secondary" onClick={handleCancel}>
            {t("edit.button.cancel")}
          </Button>
          <Button
            color="primary"
            type="submit"
            form="fieldPreparation-event-input-form"
          >
            {t("edit.button.save")}
          </Button>
        </ButtonGroup>
        <ConfirmCancellationDialog
          dialogOpen={open}
          closeDialog={() => setOpen(false)}
          onConfirmCancel={() => handleConfirmationClose()}
          onCancelCancel={() => setOpen(false)}
        />
      </ButtonContainer>
    </>
  );

  function handleConfirmationClose() {
    mixpanel.track("FieldPreparationEvent creation cancelled");
    handleClose();
  }

  function handleCancel() {
    setOpen(true);
  }
}

const StyledTextField = styled(TextField)(
  ({ theme }) => css`
                          min - width: 300px;
                    padding - bottom: ${theme.spacing(2)};
                    `
);
