import DropdownInput from "common/components/DropdownInput";
import { useTranslation } from "common/locales";
import {
  Theme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { GRASS_TYPE } from "model/fieldSlice";

interface GrassTypeDropdownProps {
  currentGrassType?: string;
  disabled: boolean;
  onChange: (value?: GRASS_TYPE) => void;
  grassTypeInputError: boolean;
  label: string;
}

const useVegetationStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      maxWidth: 400,
      paddingBottom: theme.spacing(2),
    },
  })
);

export default function GrassTypeDropdown({
  disabled,
  onChange,
  currentGrassType,
  grassTypeInputError,
  label,
}: GrassTypeDropdownProps) {
  const classes = useVegetationStyles();
  const { t } = useTranslation();

  return (
    <FormControl
      className={classes.formControl}
      disabled={disabled}
      error={grassTypeInputError}
      fullWidth
    >
      <InputLabel
        id="assignedGroup-select-label"
        variant="standard"
        shrink={true}
        error={grassTypeInputError}
      >
        {t(label)}
      </InputLabel>
      <Select
        labelId="assignedGroup-select-label"
        id="assignedGroup-select"
        value={currentGrassType ?? ""}
        onChange={onChangeSelect}
        input={<DropdownInput />}
        error={grassTypeInputError}
      >
        {Object.entries(GRASS_TYPE).map(([key, value]) => (
          <MenuItem value={value} key={key}>
            {t(`fieldinput.grassType.option.${value}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  function onChangeSelect(e: any) {
    onChange(e.target.value);
  }
}
